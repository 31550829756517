import React from 'react';

//Thông tin người tạo trang web cưới trang
class Footer extends React.Component {
    render() {
        return ''
        // return (
        //   <footer className="footer text-center">
        //     All Rights Reserved by Vilaco admin. Designed and Developed by Thanh Tùng
        //     <a href="https://wrappixel.com">tongthanhtung315@gmail.com</a>.
        //   </footer>
        // );

    }
}

export default Footer;
